import React, { useState } from 'react'
import './StudyResources.css'
import Mm11 from './Mm11'
import Mm12 from './Mm12'
function Mm() {

        const [subjetct, setsubjetct] = useState()

        const showsubjects=(sub)=>{
            setsubjetct(sub)
        }

       

  return (
    <div className='freeresource-container cd:w-[80%] pb-10 cd:pb-0'>
        
        <h1 className='text-3xl p-5 text-center sm:text-left'>Find Below the Mind maps by choosing your class </h1>
            {/* <p style={{ fontFamily: "Manrope",fontSize:"15px",width:"95%",margin:"auto" }}>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt ratione quod aut accusamus cumque libero eligendi. Nobis, eos amet modi voluptatum reprehenderit iste dolorem cum distinctio soluta maiores praesentium atque, voluptas alias ratione omnis!</p> */}

        <div className='dpp-clas s sm:w-fit flex flex-col sm:flex-row items-center justify-center space-y-5 sm:space-y-0 w-full sm:space-x-5 p-10 pt-5 text-white'>
            <div className="subject s bg-[#404bfde6] flex items-center justify-center rounded-xl w-fit h-fit p-5 px-10">
            <button onClick={()=>{showsubjects("class12")}}>Class 11 </button>
            </div>

            <div className="subject s bg-[#404bfde6] flex items-center justify-center rounded-xl w-fit h-fit p-5 px-10">
            <button onClick={()=>showsubjects("class11")}>Class 12 </button>

            </div>
    </div>

    {subjetct === "class11"?<><Mm11 /></>:null }
    {subjetct === "class12"?<><Mm12/></>:null }




    </div>
  )
}

export default Mm