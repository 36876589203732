import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function StudyResources() {
  const navigate = useNavigate();
  return (
    <div className="freeresource-container w-full cd:w-[80%] overflow-x-hidden">
      <h1 className="text-3xl p-5 text-center sm:text-left">
        Find Below the PDF of Notes by choosing subjects{" "}
      </h1>
      {/* <p style={{ fontFamily: "Manrope",fontSize:"15px",width:"75%",margin:"auto" }}>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt ratione quod aut accusamus cumque libero eligendi. Nobis, eos amet modi voluptatum reprehenderit iste dolorem cum distinctio soluta maiores praesentium atque, voluptas alias ratione omnis!</p> */}

      <div className="note s cd:w-fit flex flex-col sm:flex-row items-center justify-center space-y-5 sm:space-y-0 w-full sm:space-x-5 p-10 pt-5 text-white">
        <div className="subject s bg-[#404bfde6] flex items-center justify-center rounded-xl w-fit h-fit p-5 px-14 sm:px-10">
          <button
            onClick={() => {
              navigate("/dashboard/studyresources/jee/notes");
            }}
            style={{ height: "80px" }}
          >
            NOTES{" "}
          </button>
        </div>

        <div className="subject s bg-[#404bfde6] flex items-center justify-center rounded-xl w-fit h-fit p-5 sm:px-12 px-16">
          <button
            onClick={() => {
              navigate("/dashboard/studyresources/jee/dpps");
            }}
            style={{ height: "80px" }}
          >
            DPPs{" "}
          </button>
        </div>

        <div className="subject s bg-[#404bfde6] flex items-center justify-center rounded-xl w-fit h-fit p-5 px-16 sm:px-12">
          <button
            onClick={() => {
              navigate("/dashboard/studyresources/pyq");
            }}
            style={{ height: "80px" }}
          >
            PYQ{" "}
          </button>
        </div>

        <div className="subject s bg-[#404bfde6] flex items-center justify-center rounded-xl w-fit h-fit p-5 sm:px-7 px-10">
          <button
            onClick={() => {
              navigate("/dashboard/studyresources/mindmaps");
            }}
            style={{ height: "80px" }}
          >
            MIND MAPS{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default StudyResources;
