import React, { useRef, useState } from 'react'
import './StudyResources.css'
import physics11 from '../dpps/physics11.json'
import maths11 from '../dpps/maths11.json'
import physical11 from '../dpps/physical11.json'
import organic11 from '../dpps/organic11.json'
import inorganic11 from '../dpps/inorganic11.json'

import Embedpdf from './Embedpdf'

function Dpp11() {
    const [subject, setsubject] = useState('')
    const handlesubject = (sub) => {
        setsubject(sub)
        setid("pdfs")

        //   console.log(physics11);
    }

    const [chem, setchem] = useState('')
    const handlechem = (sub) => {
        setchem(sub)
        setid("pdfs")

        
        //   console.log(physics11);
    }

    const [id, setid] = useState("")

   const scroll =()=>{
    console.log("pdfs")
    const titleElement = document.getElementById('pdfs')
    console.log(id)
    console.log(titleElement)
    titleElement.scrollIntoView({ behavior: 'smooth' })
   }


    return (
        <div className='w-full'>

            <h1 className='text-3xl p-5 text-center sm:text-left'>Find Below the Dpps of class 11 </h1>
           
            <div className='subs-container flex items-center justify-center'>
                <div className="subjects">
                    <span className='text-lg font-semibold'>Physics</span>
                    <p>Give momentum to your Physics preparation with our Daily Practice papers.</p>
                    <button className='font-semibold flex items-center justify-center space-x-1' onClick={() => {handlesubject("physics"); scroll();}}>
                        <span>Show preview</span>
                        <img src="arrow.png" alt='arrow' />
                    </button>
                </div>
                <div className="subjects">
                    <span className='text-lg font-semibold'>Chemistry</span>
                    <p>Chemistry will come to you organically, with our Daily Practice papers</p>
                    <button className='font-semibold flex items-center justify-center space-x-1' onClick={() =>{handlesubject("chemistry"); scroll(); }}>
                        <span>Show preview</span>
                        <img src="arrow.png" alt='arrow' />
                    </button>
                </div>
                <div className="subjects">
                    <span className='text-lg font-semibold'>Mathematics</span>
                    <p>Add-on to your Maths preparation, with our Daily Practice papers.</p>
                    <button className='font-semibold flex items-center justify-center space-x-1' onClick={() => {handlesubject("mathematics"); scroll();} }>
                        <span>Show preview</span>
                        <img src="arrow.png" alt='arrow' />
                    </button>
                </div>
            </div>

            {subject === "chemistry" ? <>
                <div className="topics-container pb-5" id={id}>
                    <div className="subs" onClick={() => { handlechem("organic"); scroll() }}>Organic chemistry </div>
                    <div className="subs" onClick={() => {handlechem("physical"); scroll()}}>Physical chemistry </div>
                    <div className="subs" onClick={() => {handlechem("inorganic"); scroll()}}>Inorganic chemistry </div>

                </div>

                {chem === "physical" ?
                <>
                    <h1 style={{ fontFamily: "Manrope", margin: "auto", width: "75%", fontSize: "34px", borderBottom: "1px solid black", marginTop: "30px" }} id={id}>Physical Chemistry Dpp</h1>

                    <div className="dpp-pdfs">

                        {physical11.map((maths) => {
                            return <>


                                <Embedpdf pyqembed={maths.file_link} pyqname={maths.file_name.charAt(0).toUpperCase() + maths.file_name.replace("-", " ").replace("-", " ").replace("-", " ").replace("_", " ").slice(1, -4)} />

                            </>

                        })}

                    </div>
                </> : null} {chem === "organic" ?
                    <>
                    <h1 style={{ fontFamily: "Manrope", margin: "auto", width: "75%", fontSize: "34px", borderBottom: "1px solid black", marginTop: "30px" }}  id={id} >Organic Chemistry Dpp</h1>

                    <div className="dpp-pdfs">


                        {organic11.map((maths) => {
                            return <>


                                <Embedpdf pyqembed={maths.file_link} pyqname={maths.file_name.charAt(0).toUpperCase() + maths.file_name.replace("-", " ").replace("-", " ").replace("-", " ").replace("_", " ").slice(1, -4)} />

                            </>

                        })}
                    </div>

                    </> : null} {chem === "inorganic" ?
                        <>
                    <h1 style={{ fontFamily: "Manrope", margin: "auto", width: "75%", fontSize: "34px", borderBottom: "1px solid black", marginTop: "30px" }}  id={id} >Inorganic Chemistry Dpp</h1>

                        <div className="dpp-pdfs">


                            {inorganic11.map((maths) => {
                                return <>


                                    <Embedpdf pyqembed={maths.file_link} pyqname={maths.file_name.charAt(0).toUpperCase() + maths.file_name.replace("-", " ").replace("-", " ").replace("-", " ").replace("_", " ").slice(1, -4)} />

                                </>

                            })}
                        </div>

                        </> : null}


            </> : null}

            {subject === "physics" ?
                <>
                    <h1 style={{ fontFamily: "Manrope", margin: "auto", width: "75%", fontSize: "34px", borderBottom: "1px solid black", marginTop: "30px" }} id={id} >Physics Dpp</h1>
                    <div className="dpp-pdfs">

                        {physics11.map((maths) => {
                            return <>


                                <Embedpdf pyqembed={maths.file_link} pyqname={maths.file_name.charAt(0).toUpperCase() + maths.file_name.replace("-", " ").replace("-", " ").replace("-", " ").replace("_", " ").slice(1, -4)} />

                            </>

                        })}
                    </div>

                </> : null}

            {subject === "mathematics" ?
                <>
                    <h1 style={{ fontFamily: "Manrope", margin: "auto", width: "75%", fontSize: "34px", borderBottom: "1px solid black", marginTop: "30px" }} id={id}>Mathematics Dpp</h1>
                    <div className="dpp-pdfs">

                    {maths11.map((maths) => {
                        return <>


                            <Embedpdf pyqembed={maths.file_link} pyqname={maths.file_name.charAt(0).toUpperCase() + maths.file_name.replace("-", " ").replace("-", " ").replace("-", " ").replace("_", " ").slice(1, -4)} />

                        </>

                    })}
                    </div>

                </> : null}

           




        </div>

    )
}

export default Dpp11



